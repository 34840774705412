import { b2b_cars } from "@prisma/client";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import isString from "lodash/isString";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const formatPrintType = (printType: string | null) => {
  const type = printType?.includes("A") ? "A" : "W";
  const size = printType?.substring(0, 2);

  switch (size) {
    case "15":
      return `15x10${type}`;
    case "20":
      return `20x15${type}`;
    case "30":
      return `30x20${type}`;
    case "60":
      return `60x40${type}`;
    case "90":
      return `90x60${type}`;
    default:
      return printType;
  }
};

export const getFileName = (car: b2b_cars) => {
  const dealerId = car?.userDefined?.respDealerId;
  const dealerName =
    car?.businessPartnerName || car?.userDefined?.respDealerName || car?.userDefined?.respSalesDealerName1;
  const centerId = dealerId ? `-${dealerId}` : "";
  const centerName = dealerName ? `-${dealerName}` : "";
  const center = centerId + centerName;
  const printType = formatPrintType(car.printType);

  return `${printType}${center}/${car.excelNr}_${printType}.${car.ext || "png"}`;
};

export const downloadFile = async (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

export const getBrandByDesign = (design: string | null) => {
  switch (design) {
    case "spotlight":
    case "runway":
    case "accent":
    case "bold":
    case "profile":
    case "pure":
      return "audi";
    default:
      return "porsche";
  }
};

export const bytesToSize = (bytes?: number, decimals = 2): string => {
  if (!bytes || bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`;
};
